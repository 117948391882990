<template>
  <!-- <div class="list"> -->
    <div class="nankaiContent">
          <div class="search">
            <van-search
              v-model="keytitle"
              show-action
              placeholder="请输入搜索关键词"
              @search="onSearch"
            >
              <template #action>
                <div @click="onSearch">搜索</div>
              </template>
            </van-search>
          </div>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="load_more"
          >
          <div class="listFor">
            <div v-for="i in pubilcArr" :key="i.id" class="nankaiItem" @click="onlist(i)">
                <div class="nankaiImg" v-if="type != 'nk' && listType ==1 ||listType == 2 ">
                  <img v-lazy="i.coverPhoto" alt="">
                </div>
                <div class="nankaiTittle">
                  <div class="matter">{{i.title}}</div>
                  <div class="nankaiTag">
                    <van-tag color="#E3F7FF" text-color="#0073C0" v-for="(item,index) in i.tagInfo" :key="index">{{item}}</van-tag>
                  </div>
                  <div class="time" v-if="type == 'nk' || listType == 1 || listType == 3">{{i.publishTime}}</div>
                </div>
              </div>
          </div>
          </van-list>
          <goPage v-if="$store.state.tyPhone == 'ios'"></goPage>
        </div>
  <!-- </div> -->
</template>
<script>
import { getContentRecList,getInfoList,getContentByColumnId } from "@/api/home.js";
export default {
  name:"list",
//   props:["pubilcArr"],
  data() {
    return {
      pubilcArr:[],
      keytitle:'',
      list:[
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:require('@/assets/gdyx.png')},
        {img:require('@/assets/gdyx.png')},
        {img:require('@/assets/gdyx.png')}
      ],
      type:"",
      listType:'',
      loading: false,
      finished: false,
      page:1
    }
  },
  watch:{
  },
  computed:{
    advertisingId(){
      return this.$store.state.advertisingId
    }
  },
  created(){
    // this.title = localStorage.getItem('moresList')
    this.init()
  },
  methods:{
    onSearch(){
      this.page = 1;//页数+1
      this.pubilcArr = []
      this.finished = true
      this.init()
    },
    load_more: function() {
      this.page += 1;//页数+1
      this.init();
    },
    async init(){
      this.title = localStorage.getItem('moresList')
      if(this.$store.state.jxbApp){
        window.location.href=`ssptitle://${this.title}`
      }else{
        document.title = this.title
      }
      this.type = this.$route.query.type
      this.listType = this.$route.query.listType
      if(this.$route.query.type == 'nk'){
        let data = await getContentRecList({
          "pageNumber": this.page,
          "pageSize": 10,
          "title":this.keytitle
        })
        let arr = data.data.data.rows
        arr.map(item => {
          if(item.tagInfo){
            item.tagInfo = item.tagInfo.split(",")
          }
        })
        this.loading = false;
        // if(data.data.data.currPage == 1){
        //   this.pubilcArr = arr
        // }else
         if(this.pubilcArr.length == data.data.data.total){
          
        }else{
          this.pubilcArr = this.pubilcArr.concat(arr);//追加数据
        }
        if (this.page == data.data.data.totalPage || this.page > data.data.data.totalPage) {  //数据全部加载完成
          this.finished = true;
        }else{
          this.finished = false;
        } 
      }else{
        const ContentRecListData = await getContentByColumnId({
            "columnId":this.$route.query.xxId,
            "pageNumber": this.page,
            "pageSize": 10,
            "title":this.keytitle
          })
          console.log(ContentRecListData)
          let arr = ContentRecListData.data.data.rows
          if(arr){
            arr.map(item => {
              if(item.tagInfo){
                item.tagInfo = item.tagInfo.split(",")
              }
            })
          }
          this.loading = false;
          if(this.pubilcArr.length == ContentRecListData.data.data.total){
            
            
          }else{
            this.pubilcArr = this.pubilcArr.concat(arr);//追加数据
          }
          if (this.page == ContentRecListData.data.data.totalPage || this.page > ContentRecListData.data.data.totalPage) {  //数据全部加载完成
            this.finished = true;
          }else{
            this.finished = false;
          } 
      }
    },
    async onlist(item){
      console.log(item)
      this.$store.commit('setTopTittle',item.title)
           // 首页数据
      this.$store.commit('setMenulist',item)
      // 传递id
      this.$router.push({path:'/nkopen',query:{categoryId:item.id}})

      this.$store.commit('setDetailsId',item.id)

      this.$store.commit('setStyleType',1)
      
      this.$store.commit('setMenuType','lanmu')
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.van-tag{
  margin-right: .089rem;
}
.nankaiContent{
          margin-top: 1.478rem;
          padding: 0 0.355556rem;
          box-sizing: border-box;
          .search{
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
          }
        .nankaiItem{
          display: flex;
          border-bottom: .009rem solid #efecec;
          margin-bottom: .347rem;
          padding-bottom: 0.347rem;
          text-align: left;
          
          .nankaiTittle{
            font-size: .356rem;
            color: #000000;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .time{
              color: #92999F;
              font-size: .302rem;
            }
            .matter{
              font-size: .39rem;
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;//控制行数
              overflow:hidden;
              // width: 5.796rem;
            }
          }
          .nankaiImg{
            width: 3.022rem;
            height: 2.133rem;
            margin-right: .356rem;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .nankaiTag{
            
          }
        }
        .listFor{
          margin-top: 1.3rem;
        }
      }
// .list{
//   .listNav{
//     width: 9.164rem;
//     margin: 0 auto;
//     height: 2.32rem;
//     background-size: 100% 100%;
//     margin-bottom: .507rem;
//     text-align: left;
//     padding-left: .684rem;
//     padding-top: .513rem;
//     box-sizing: border-box;
//     color: #FFFFFF;
//   }
//   .listNav:nth-child(1){
//     margin-top: .271rem;
//   }
//   .higherLearning{
//     font-size: .444rem;
//     font-weight: 500;
//   }
//   .talent{
//     font-weight: 400;
//     font-size: .356rem;
//     margin-top: .256rem;
//   }
// }
</style>